// @ts-nocheck
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch } from 'react-redux';

import { searchUsers } from '../userEntitySlice';

export const SelectUser: React.FC = (props) => {
  const {
    skip,
    take,
    debounceTimeout,
    value,
    onChange,
    onSelect,
    initialOptions,
    mode,
  }: any = props;

  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState(
    initialOptions?.map((p) => ({ label: p.value, value: p.value })) ?? []
  );
  const fetchRef = React.useRef(0);
  const dispatch = useDispatch();

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      if ((value?.length ?? 0) === 0) return;

      fetchRef.current += 1;
      setOptions([]);
      setFetching(true);

      const response = (
        await dispatch(
          searchUsers({
            skip: skip ?? 0,
            take: take ?? 20,
            query: value,
          })
        )
      )?.payload;

      setOptions(
        response?.items?.map((p) => ({
          label: `${p.userName} - ${p.name} ${p.surname}`,
          value: p.id,
        })) ?? []
      );
    };

    return debounce(loadOptions, debounceTimeout ?? 500);
  }, [debounceTimeout]);

  return (
    <Select
      labelInValue
      allowClear
      filterOption={false}
      value={value}
      showSearch
      style={{ width: 240 }}
      mode={mode}
      autoClearSearchValue
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      onSelect={onSelect}
      onChange={onChange}
      options={options}
    />
  );
};
