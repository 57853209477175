// @ts-nocheck
import { Modal } from 'antd';
import axios from 'axios';

export const apiInstance = axios.create({
  // baseURL: 'https://localhost:7117'
  baseURL: 'https://console.hoopt.io',
});

export const authKey = '@Hoopt:AuthToken';

// TODO: Set this configs according to env
const homepageUrl = 'https://console.hoopt.io';
// const homepageUrl = 'https://localhost:44438'

apiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(authKey);

    const isAuthenticationRequest = config?.url
      ?.toLowerCase()
      ?.endsWith('signup');
    if (token && !isAuthenticationRequest) {
      config.headers.Authorization = 'Bearer ' + token;
      config.validateStatus = () => true;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const isDevelopment = window.location.hostname === 'localhost';
apiInstance.interceptors.response.use(
  (response) => {
    if (response?.status === 401) {
      localStorage.removeItem(authKey);
      Modal.error({
        title: 'You need to sign in',
        content: 'Since your session is over, you need to sign in again.',
        onOk() {
          window.top.location.href = isDevelopment
            ? 'https://localhost:44438'
            : homepageUrl;
        },
      });
    }
    return response;
  },
  async (error) => {
    localStorage.removeItem(authKey);
    Modal.error({
      title: 'You need to sign in',
      content: 'Since your session is over, you need to sign in again.',
      onOk() {
        window.top.location.href = isDevelopment
          ? 'https://localhost:44438'
          : homepageUrl;
      },
    });
    const result = Promise.reject(error?.response?.data || error);
    console.log(result);
    return result;
  }
);

export const useRequest = () => {
  const get = (url) => apiInstance.get(url);
  const post = (url, data) => apiInstance.post(url, data);
  const put = (url, data) => apiInstance.put(url, data);
  const deleteRequest = (url) => apiInstance.delete(url);
  const postForm = (url, data) =>
    apiInstance.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  return { get, post, postForm, deleteRequest, put };
};
