// @ts-nocheck
import {
  Button,
  DatePicker,
  Form,
  Input,
  notification,
  PageHeader,
  Row,
  Table,
  Tag,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAllInvites } from '../invitesTracker';

type Props = {};

export const SearchInvitesPage: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const paginatedData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const tableColumns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
    },
    {
      title: 'Username',
      dataIndex: 'userName',
    },
    {
      title: 'Remaining Invites',
      dataIndex: 'remainingInviteCount',
    },
    {
      title: 'Total Invites',
      dataIndex: 'totalInviteCount',
    },
    {
      title: 'Invite Link',
      dataIndex: 'inviteLink',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Is Used',
      dataIndex: 'isUsed',
      render: (isUsed) => (
        <Tag color={isUsed ? 'green' : 'red'}>
          {isUsed ? 'Used' : 'Not Used'}
        </Tag>
      ),
    },
    {
      title: 'Used User ID',
      dataIndex: 'usedUserId',
    },
    {
      title: 'Used Full Name',
      dataIndex: 'usedUserFullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
  ];
  useEffect(() => {
    fetchData({});
  }, []);

  const fetchData = async () => {
    setLoading(true);
    let allData = [];
    let page = 1;
    const pageSize = 50;

    try {
      while (true) {
        const response = await dispatch(
          fetchAllInvites({ skip: (page - 1) * pageSize, take: pageSize })
        ).unwrap();

        if (!response?.items?.length) break;

        const usersLookup = Object.fromEntries(
          response.lookups.users.map((user) => [user.id, user])
        );

        let enrichedData = response.items.map((item) => {
          const user = usersLookup[item.userId] || {};
          const usedUser = usersLookup[item.usedUserId] || {};
          return {
            ...item,
            name: user.name,
            surname: user.surname,
            userName: user.userName,
            remainingInviteCount: user.remainingInviteCount,
            totalInviteCount: user.totalInviteCount,
            usedUserName: usedUser.userName,
            usedUserFullName: `${usedUser.name || ''} ${
              usedUser.surname || ''
            }`,
            createdAt: new Date(item.createdAt),
          };
        });

        allData = [...allData, ...enrichedData];

        if (response.items.length < pageSize) break;
        page++;
      }

      allData.sort((a, b) => b.createdAt - a.createdAt);

      setOriginalData(allData);
      setData(allData);
      setPagination({
        ...pagination,
        total: allData.length,
        current: 1,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch invites.',
      });
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let filteredData = [...originalData];

    if (formData.query) {
      const searchQuery = formData.query.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.userName?.toLowerCase().includes(searchQuery) ||
          item.name?.toLowerCase().includes(searchQuery) ||
          item.surname?.toLowerCase().includes(searchQuery) ||
          item.phoneNumber?.toLowerCase().includes(searchQuery) ||
          item.invitationCode?.toLowerCase().includes(searchQuery)
      );
    }

    if (formData.dateRange && formData.dateRange.length === 2) {
      const [startDate, endDate] = formData.dateRange;
      filteredData = filteredData.filter((item) => {
        const createdAt = new Date(item.createdAt);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    filteredData.sort((a, b) => b.createdAt - a.createdAt);

    setData(filteredData);
    setCurrentPage(1);
  };

  const onFieldsChange = () => {
    const newFormData = form.getFieldsValue();
    setFormData(newFormData);
    applyFilters();
  };

  const onFinish = () => {
    applyFilters();
  };

  const onReset = () => {
    form.resetFields();
    setFormData({});
    setData(originalData);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Invites Tracker"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <Form
              layout="inline"
              form={form}
              style={{ width: '100%' }}
              initialValues={formData}
              onValuesChange={onFieldsChange}
              onFinish={onFinish}
            >
              <Form.Item
                name="dateRange"
                label="Date Range"
                style={{ width: 350 }}
              >
                <DatePicker.RangePicker
                  onChange={(dates) => {
                    setFormData({ ...formData, dateRange: dates });
                  }}
                />
              </Form.Item>
              <Form.Item name="query" label="Username" style={{ width: 250 }}>
                <Input placeholder="Enter username" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={loading} type="primary">
                  Search
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="reset"
                  loading={loading}
                  onClick={onReset}
                  type="default"
                >
                  Reset
                </Button>
              </Form.Item>
            </Form>
          </Row>
          <Table
            loading={loading}
            columns={tableColumns}
            dataSource={paginatedData}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: data.length,
              onChange: (page) => setCurrentPage(page),
            }}
            rowKey="invitationCode"
          />
        </div>
      </Content>
    </div>
  );
};
