// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const groupEntitySlice = createSlice({
  name: 'groupEntity',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setGroupEntityLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    },
  },
});

export const searchGroups = createAsyncThunk(
  'Group/SearchGroups',
  async (payload: { skip: number; take: number; query: string }) => {
    const { post } = useRequest();
    return (await post('Group/SearchGroups', payload))?.data as GroupResponse;
  }
);

export const { setGroupEntityLoading } = groupEntitySlice.actions;
export const getGroupEntityLoading = ({ groupEntity }) => groupEntity.isLoading;
export default groupEntitySlice.reducer;
