// @ts-nocheck
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  getCollectionAttributeById,
  getCollectionAttributeLoading,
  setCollectionAttributeLoading,
  updateCollectionAttribute,
} from '../collectionAttributeSlice';

type Props = {};

export const CollectionAttributeDetailPage: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(getCollectionAttributeLoading);
  const { collectionAttributeId } = useParams();
  const [childAttributes, setChildAttributes] = useState([]);
  const [parentAttribute, setParentAttribute] = useState([]);

  useEffect(() => {
    if (collectionAttributeId > 0) {
      getDetails(collectionAttributeId);
    } else {
      form.resetFields();
      setFormData(undefined);
    }
  }, [collectionAttributeId]);

  const getDetails = async (collectionAttributeId) => {
    if ((collectionAttributeId ?? 0) === 0) return;

    try {
      await dispatch(setCollectionAttributeLoading(true));
      const response: any = await dispatch(
        getCollectionAttributeById(collectionAttributeId)
      );
      form.setFieldsValue(response.payload);
      setFormData(response.payload);
      setChildAttributes(response.payload.childCollectionAttributes);
      const parentCollection = [];
      parentCollection.push(response.payload.parentCollectionAttribute);
      setParentAttribute(parentCollection);
      console.log(parentCollection);

      await dispatch(setCollectionAttributeLoading(false));
    } catch (e) {
      message.error(e.message);
      await dispatch(setCollectionAttributeLoading(false));
    }
  };

  const onFinish = async (values: any) => {
    try {
      await dispatch(setCollectionAttributeLoading(true));
      await dispatch(updateCollectionAttribute(values));
      await dispatch(setCollectionAttributeLoading(false));
      message.success('Update successfully');
      history.push('/collection-attributes/search');
    } catch (e) {
      dispatch(setCollectionAttributeLoading(false));
      message.error(e.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFieldsChange = (changedFields) => {
    setFormData({ ...formData, changedFields });
  };

  const EntityDetailTable = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'TotalCount',
      dataIndex: 'totalCount',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (_: any, record: any) =>
        record?.id > 0 ? (
          <a
            href={`/collection-attributes/detail/${record.id}`}
            target="_blank"
            rel="noreferrer"
          >
            Edit - {record.value}
          </a>
        ) : (
          <>{record?.value}</>
        ),
    },
  ];

  return (
    <div>
      11
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title="Collection Attribute"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Spin spinning={isLoading}>
            <Row>
              <Col span={12}>
                <Form
                  name="collectionAttribute"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  form={form}
                  initialValues={formData}
                  onFinish={onFinish}
                  onChange={onFieldsChange}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item label="id" hidden name="id">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label="Collection Attribute Type"
                    rules={[
                      {
                        required: true,
                        message:
                          'Please input entity collection attribute type',
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        setFormData({ ...formData, type: value });
                      }}
                    >
                      <Select.Option value="Country">Country</Select.Option>
                      <Select.Option value="City">City</Select.Option>
                      <Select.Option value="County">County</Select.Option>
                      <Select.Option value="PlaceTypes">
                        PlaceTypes
                      </Select.Option>
                      <Select.Option value="Genre">Genre</Select.Option>
                      <Select.Option value="Director">Director</Select.Option>
                      <Select.Option value="Author">Author</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Value"
                    name="value"
                    rules={[
                      { required: true, message: 'Please input entity value' },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Is Active"
                    name="isActive"
                    rules={[
                      {
                        required: true,
                        message: 'Please input entity sub title',
                      },
                    ]}
                  >
                    <Switch
                      checked={formData?.isActive ?? false}
                      onChange={(checked) => {
                        setFormData({ ...formData, isActive: checked });
                      }}
                    />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col span={18}>
                {childAttributes?.length ? (
                  <Card title="Child Attributes">
                    <Table
                      size="small"
                      key={'subTable_'}
                      rowKey={(record) => 'subTable_' + record?.id}
                      pagination={false}
                      dataSource={childAttributes}
                      columns={EntityDetailTable}
                    />
                  </Card>
                ) : (
                  <></>
                )}

                {parentAttribute?.length ? (
                  <Card title="Parent Attribute">
                    <Table
                      size="small"
                      key={'subTable_'}
                      rowKey={(record) => 'subTable_' + record?.id}
                      pagination={false}
                      dataSource={parentAttribute}
                      columns={EntityDetailTable}
                    />
                  </Card>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Spin>
        </div>
      </Content>
    </div>
  );
};
