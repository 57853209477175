// @ts-nocheck
import './SearchGroups.css';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  notification,
  PageHeader,
  Row,
  Skeleton,
  Spin,
  Table,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useRequest } from '../../utils/hooks/requestHook';
import UpdateGroup from '../components/UpdateGroup';
import { searchGroups } from '../groupEntitySlice';
export const SearchGroups: React.FC<any> = () => {
  const dispatch = useDispatch();
  const isLoading = false;

  const [results, setResults] = React.useState([]);
  const [response, setResponse] = React.useState({});
  const [selectedGroupMembers, setSelectedGroupMembers] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = React.useState(false);

  const fetchRef = React.useRef(0);
  const skip = 0;
  const take = 50;
  const debounceTimeout = 300;
  const { post } = useRequest();
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: false,
      name: record.name,
    }),
  };

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      if ((value.target.value?.length ?? 0) === 0) return;

      fetchRef.current += 1;
      setResults([]);

      const response = (
        await dispatch(
          searchGroups({
            skip: skip ?? 0,
            take: take ?? 20,
            query: value.target.value,
          })
        )
      )?.payload;

      setResults(response?.items || []);
      setResponse(response);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout]);

  const handleShowMembers = (members) => {
    setSelectedGroupMembers(members);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedGroupMembers([]);
  };
  const handleCloseUpdateModal = () => {
    setIsUpdateModalVisible(false);
    setSelectedGroup(null);
  };

  const handleOpenUpdateModal = (group) => {
    setSelectedGroup(group);
    setIsUpdateModalVisible(true);
  };

  const GroupDetailTable = [
    {
      title: '',
      dataIndex: 'groupPicture',
      key: 'groupPicture',
      width: 150,
      render: (_: any, record) => {
        const isImageUrl = record.groupPicture?.startsWith('http');

        return isImageUrl ? (
          <Image
            height={75}
            width={75}
            style={{ objectFit: 'cover', borderRadius: '50%' }}
            key={`row_image_${record.id}`}
            src={`${record.groupPicture}&timestamp=${new Date().getTime()}`}
            preview={{
              src: record.groupPicture?.length > 0 ? record.groupPicture : '',
            }}
          />
        ) : (
          <div
            style={{
              height: 75,
              width: 75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: '#2D2D2D',
              color: '#fff',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {record.groupPicture}
          </div>
        );
      },
    },

    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record) => (
        <span
          style={{
            color: '#1890ff',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => handleOpenUpdateModal(record)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Creator User',
      dataIndex: 'creatorUser',
      key: 'creatorUser',
    },
    {
      title: 'Privacy',
      dataIndex: 'privacy',
      key: 'privacy',
    },
    {
      title: 'Creation Time (Locale)',
      dataIndex: 'createdOnUtc',
      key: 'createdOnUtc',
      render: (_: any, record: any) =>
        new Date(record.createdOnUtc).toLocaleString(),
    },
    {
      title: 'Operations',
      dataIndex: 'operations',
      key: 'operations',
      render: (_: any, record: any) => (
        <button
          onClick={() => handleShowMembers(record.members)}
          style={{
            background: '#1890ff',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Show Members
        </button>
      ),
    },
  ];
  const handleDisableGroups = async () => {
    Modal.confirm({
      title: 'Are you sure you want to disable the selected groups?',
      icon: <ExclamationCircleOutlined />,
      content:
        'This action will disable the selected groups and cannot be undone.',
      okText: 'Yes, Disable',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await post('/Group/DisableGroups', selectedRowKeys);
          notification.success({
            message: 'Success',
            description: 'Selected groups have been disabled.',
          });
          setSelectedRowKeys([]);
          debounceFetcher({ target: { value: '' } });
        } catch (error) {
          console.error('Error disabling groups:', error.message);
          notification.error({
            message: 'Error',
            description: 'Failed to disable selected groups.',
          });
        }
      },
    });
  };

  const handleEnableGroups = async () => {
    try {
      await post('/Group/ReActiveGroups', selectedRowKeys);
      notification.success({
        message: 'Success',
        description: 'Selected groups have been enabled.',
      });
      setSelectedRowKeys([]);
      debounceFetcher({ target: { value: '' } });
    } catch (error) {
      console.error('Error enabling groups:', error.message);
      notification.error({
        message: 'Error',
        description: 'Failed to enable selected groups.',
      });
    }
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Search Groups"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <div style={{ width: '100%' }}>
              <Spin spinning={isLoading}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <Form layout={'inline'}>
                      <Form.Item
                        label="Group Name"
                        name="groupName"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a group name',
                          },
                        ]}
                      >
                        <Input
                          onChange={debounceFetcher}
                          placeholder="Search group name"
                        />
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col>
                    {response?.totalCount && (
                      <p>
                        Total {response?.totalCount} records found. Showing max{' '}
                        {take || 20}.
                      </p>
                    )}
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      disabled={!selectedRowKeys.length}
                      onClick={handleDisableGroups}
                      style={{ marginRight: 8 }}
                    >
                      Disable Selected Groups
                    </Button>
                    <Button
                      type="primary"
                      disabled={!selectedRowKeys.length}
                      onClick={handleEnableGroups}
                    >
                      Enable Selected Groups
                    </Button>
                  </Col>
                </Row>
              </Spin>
            </div>
          </Row>
        </div>

        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Spin spinning={isLoading}>
            <Skeleton loading={isLoading} active>
              {results?.length ? (
                <Card>
                  <Table
                    rowKey={(record) => record.id}
                    size="small"
                    columns={GroupDetailTable}
                    dataSource={results}
                    pagination={false}
                    rowSelection={rowSelection}
                    rowClassName={(record) =>
                      record.isDeleted ? 'row-disabled' : ''
                    }
                  />
                </Card>
              ) : (
                <Card>
                  <Empty description="No groups found" />
                </Card>
              )}
            </Skeleton>
          </Spin>
        </div>
      </Content>
      {selectedGroup && (
        <UpdateGroup
          open={isUpdateModalVisible}
          onClose={handleCloseUpdateModal}
          id={selectedGroup.id}
          name={selectedGroup.name}
          description={selectedGroup.description || ''}
          privacy={selectedGroup.privacy}
        />
      )}

      <Modal
        title="Group Members"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedGroupMembers?.length ? (
          selectedGroupMembers.map((member, index) => (
            <p key={index}>
              {member.name} {member.surname} ({member.role})
            </p>
          ))
        ) : (
          <Empty description="No members available" />
        )}
      </Modal>
    </div>
  );
};
