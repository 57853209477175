// @ts-nocheck
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  PageHeader,
  Row,
  Spin,
  Table,
  Tag,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';

import { useRequest } from '../../utils/hooks/requestHook';

export const CreateInviteCode: React.FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const { get, post } = useRequest();

  const fetchStaticInviteCodes = async () => {
    setLoading(true);
    try {
      const response = await get('Setting/GetStaticInvitationCode');
      setData(response.data || []);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch static invite codes.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddInviteCode = async (values: any) => {
    setLoading(true);
    try {
      const sanitizedInviteCode = values.InviteCode.replace(/\s+/g, '');

      const currentCodesResponse = await get('Setting/GetStaticInvitationCode');
      const currentCodes = currentCodesResponse?.data || [];
      const updatedCodes = [...currentCodes, sanitizedInviteCode];
      const response = await post(
        'Setting/UpdateStaticInvitationCode',
        updatedCodes
      );

      if (response?.data?.success) {
        setData(updatedCodes);
      } else {
        setData(updatedCodes);
      }
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description:
          error?.response?.data?.message ||
          'Failed to update static invite codes.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveInviteCode = async (inviteCode: string) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `Do you really want to remove the invite code: ${inviteCode}? This action cannot be undone.`,
      okText: 'Yes, Remove',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        setLoading(true);
        try {
          const updatedCodes = data.filter((code) => code !== inviteCode);
          const response = await post(
            'Setting/UpdateStaticInvitationCode',
            updatedCodes
          );

          if (response?.data?.success) {
            notification.success({
              message: 'Success',
              description: 'Static invite code removed successfully!',
            });
            setData(updatedCodes);
          } else {
            setData(updatedCodes);
          }
        } catch (error: any) {
          notification.error({
            message: 'Error',
            description:
              error?.response?.data?.message ||
              'Failed to remove static invite code.',
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  useEffect(() => {
    fetchStaticInviteCodes();
  }, []);

  const tableColumns = [
    {
      title: 'Id',
      width: 30,
      dataIndex: 'id',
      render: (_: any, record, index) => (
        <Tag key={`row_id_${index}`} color="#108ee9">
          #{index + 1}
        </Tag>
      ),
    },
    {
      title: 'Invite Code',
      dataIndex: 'inviteCode',
      render: (text: string) => <div>{text}</div>,
    },
    {
      title: 'Actions',
      width: 120,
      render: (_: any, record: any) => (
        <Button
          danger
          onClick={() => handleRemoveInviteCode(record.inviteCode)}
        >
          Remove
        </Button>
      ),
    },
  ];

  const tableDataSource = data.map((code, index) => ({
    id: index + 1,
    inviteCode: code,
  }));

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Create Invite Code"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <div style={{ width: '100%' }}>
              <Spin spinning={loading}>
                <Form onFinish={handleAddInviteCode}>
                  <Form.Item
                    label="Invite Code"
                    name="InviteCode"
                    rules={[
                      { required: true, message: 'Please input Invite Code!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: 16 }}
                  >
                    Add New Static Invite Code
                  </Button>
                </Form>
              </Spin>
            </div>
          </Row>
        </div>
      </Content>
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Table
            columns={tableColumns}
            dataSource={tableDataSource}
            pagination={false}
            rowKey="id"
          />
        </div>
      </Content>
    </div>
  );
};
