// @ts-nocheck
import { Button, notification, PageHeader, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { approveUser, fetchWaitingUsers } from '../userEntitySlice';

type Props = {};

export const UserJoinRequestsPage: React.FC<Props> = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await dispatch(fetchWaitingUsers()).unwrap();
      setData(response);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch waiting users.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (userId) => {
    try {
      await dispatch(approveUser(userId)).unwrap();
      notification.success({
        message: 'Success',
        description: 'User approved successfully!',
      });
      loadUsers();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to approve user.',
      });
    }
  };

  const tableColumns = [
    { title: 'User ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Surname', dataIndex: 'surname', key: 'surname' },
    { title: 'Username', dataIndex: 'userName', key: 'userName' },
    { title: 'E-mail', dataIndex: 'email', key: 'email' },
    { title: 'Created on UTC', dataIndex: 'createdOnUtc', key: 'createdOnUtc' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleApprove(record.id)}>
          Accept
        </Button>
      ),
    },
  ];

  return (
    <div>
      <PageHeader className="site-page-header" title="User Join Requests" />
      <Content style={{ margin: '16px', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Table
            loading={loading}
            columns={tableColumns}
            dataSource={data}
            pagination={false}
            rowKey="id"
          />
        </div>
      </Content>
    </div>
  );
};
