// @ts-nocheck
import { Button, notification, PageHeader, Spin, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';

import { useRequest } from '../../utils/hooks/requestHook';

export const InviteRequests: React.FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const { get, post } = useRequest();

  const fetchInviteRequests = async () => {
    setLoading(true);
    try {
      const response = await get('/Invite/GetInviteRequests');
      const { items, lookups } = response.data;

      const enrichedData = items.map((item: any) => {
        const user = lookups.users.find((u: any) => u.id === item.userId);
        return {
          ...item,
          ...user,
        };
      });

      setData(enrichedData || []);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch invite requests.',
      });
    } finally {
      setLoading(false);
    }
  };

  const acceptInvite = async (userId: number) => {
    try {
      setLoading(true);
      const response = await post(`/Invite/ConfirmNewInvites?userId=${userId}`);
      notification.success({
        message: 'Success',
        description: `Invitation for userId ${userId} has been accepted.`,
      });

      setData((prevData) => prevData.filter((item) => item.userId !== userId));
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Failed to accept invitation for userId ${userId}.`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInviteRequests();
  }, []);

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'userId',
      key: 'userId',
      render: (text: string) => <Tag color="#108ee9">#{text}</Tag>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: 'Requested Invite Count',
      dataIndex: 'requestedInviteCount',
      key: 'requestedInviteCount',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: any) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => acceptInvite(record.userId)}
          >
            Accept
          </Button>
          <Button danger onClick={() => {}}>
            Refuse
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Invite Requests"
      />
      <Content style={{ margin: '16px', overflow: 'initial' }}>
        <Spin spinning={loading}>
          <div
            className="site-layout-background"
            style={{ padding: 24, textAlign: 'center' }}
          >
            <Table
              columns={tableColumns}
              dataSource={data}
              rowKey="userId"
              pagination={{ pageSize: 10 }}
            />
          </div>
        </Spin>
      </Content>
    </div>
  );
};
