// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const fetchWaitingUsers = createAsyncThunk(
  'User/GetWaitingUsers',
  async () => {
    const { get } = useRequest();
    return (await get('/User/GetWaitingUsers'))?.data;
  }
);

export const approveUser = createAsyncThunk(
  'User/ApproveWaitingUser',
  async (userId) => {
    const { put } = useRequest();
    const response = await put(`/User/ApproveWaitingUser?userId=${userId}`);
    return response.data;
  }
);

export const searchUsers = createAsyncThunk(
  'User/SearchUsers',
  async (payload) => {
    const { post } = useRequest();
    return (await post('User/SearchUsers', payload))?.data;
  }
);

export const userEntitySlice = createSlice({
  name: 'userEntity',
  initialState: {
    isLoading: false,
    waitingUsers: [],
    searchedUsers: [],
  },
  reducers: {
    setUserEntityLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitingUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWaitingUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.waitingUsers = action.payload;
      })
      .addCase(fetchWaitingUsers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(approveUser.fulfilled, (state, action) => {
        state.waitingUsers = state.waitingUsers.filter(
          (user) => user.id !== action.meta.arg
        );
      })
      .addCase(searchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchedUsers = action.payload;
      })
      .addCase(searchUsers.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setUserEntityLoading } = userEntitySlice.actions;
export const getUserEntityLoading = ({ userEntity }) => userEntity.isLoading;
export default userEntitySlice.reducer;
