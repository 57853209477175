import {
  DashboardOutlined,
  DatabaseOutlined,
  EditOutlined,
  FolderOutlined,
  LoginOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { clearTokens } from '../features/auth/authSlice';
import { authKey } from '../features/utils/hooks/requestHook';

const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const HooptLogo = require('../assets/images/logo_v2.png');

export const AppLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const handleClick = (e) => {
    if (e.key === 'logout') {
      // @ts-ignore
      localStorage.removeItem(authKey);
      dispatch(clearTokens());
      // @ts-ignore
      window.top.location.reload();
    } else {
      history.push(e.key);
    }
  };

  return (
    <Layout hasSider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
      >
        <div className="logo">
          <img src={HooptLogo} alt="HOOPT" />
        </div>
        <Menu
          onClick={handleClick}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['dashboard']}
        >
          <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
            Dashboard
          </Menu.Item>
          <SubMenu
            key="collection_entities_sub"
            icon={<DatabaseOutlined />}
            title="Collection Entities"
          >
            <Menu.Item key="/collection-entities/search">Search</Menu.Item>
            <Menu.Item key="/collection-entities/edit/0">Add New</Menu.Item>
            <Menu.Item key="/collection-entities/merge/">
              Merge Entities
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="collection_attributes_sub"
            icon={<FolderOutlined />}
            title="Collection Attributes"
          >
            <Menu.Item key="/collection-attributes/search">Search</Menu.Item>
            <Menu.Item key="/collection-attributes/popular-attributes">
              Popular Attributes
            </Menu.Item>
            <Menu.Item key="/collection-attributes/merge">
              Merge Attributes
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="reported_content_sub"
            icon={<FolderOutlined />}
            title="Content Management"
          >
            <Menu.Item key="/cms/pending-actions#pending">
              Pending Action
            </Menu.Item>
            <Menu.Item key="/cms/moderation-reports">
              Moderation Reports
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="user_management_sub"
            icon={<UserOutlined />}
            title="User Management"
          >
            <Menu.Item key="/user/user-join-requests">
              User Join Requests
            </Menu.Item>
            <Menu.Item key="/user/search-user-queries">
              Search User&apos;s Queries
            </Menu.Item>
            <Menu.Item key="/user/search-user">Search User</Menu.Item>
          </SubMenu>
          <SubMenu
            key="group_management_sub"
            icon={<UserOutlined />}
            title="Group Management"
          >
            <Menu.Item key="/group/search-group">Search Group</Menu.Item>
          </SubMenu>
          <SubMenu
            key="invite_links"
            icon={<UserOutlined />}
            title="Invite Links"
          >
            <Menu.Item key="/invite/search-invites">Search Invites</Menu.Item>
            <Menu.Item key="/invite/create-code">Static Invite Codes</Menu.Item>
            <Menu.Item key="/invite/invite-requests">
              Increase Invite Requests
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="/ai"
            icon={<DashboardOutlined />}
            title="AI Dashboard"
          >
            AI Dashboard
          </Menu.Item>

          <Menu.Item
            key="/testflighturl/update"
            title="Testflight Url"
            icon={<FolderOutlined />}
          >
            Testflight Url
          </Menu.Item>

          <SubMenu
            key="editor_content_sub"
            icon={<SettingOutlined />}
            title="Editor Prefences"
          >
            <Menu.Item key="/editor/new" icon={<EditOutlined />}>
              Create New Editor
            </Menu.Item>
            <Menu.Item key="/editor/preferences" icon={<EditOutlined />}>
              Change Editor Information
            </Menu.Item>
            <Menu.Item key="logout" icon={<LoginOutlined />}>
              Logout
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 250 }}>
        {/*                 <Header className="site-layout-background" style={{ padding: 0 }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                      className: 'trigger',
                      onClick: () => setCollapsed(!collapsed)
                    })}
                    <div className="header-right">
                        <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" gap={4}>
                            U
                        </Avatar>
                    </div>
                </Header> */}
        {children}
        <Footer style={{ textAlign: 'center' }}>HOOPT Dashboard</Footer>
      </Layout>
    </Layout>
  );
};
