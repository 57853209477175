// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { useRequest } from '../utils/hooks/requestHook';

export const invitesTrackerSlice = createSlice({
  name: 'invitesTracker',
  initialState: {
    isLoading: false,
    invites: [],
    users: [],
    totalCount: 0,
    error: null,
  },
  reducers: {
    setInvitesTrackerLoading: (state, action) => {
      state.isLoading = action.payload ?? false;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const fetchAllInvites = createAsyncThunk(
  'invitesTracker/fetchAllInvites',
  async ({ skip = 0, take = 20 }, { rejectWithValue }) => {
    const { get } = useRequest();
    try {
      const response = await get(
        `Invite/GetAllUserInvites?skip=${skip}&take=${take}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || 'Error fetching invites');
    }
  }
);

export const updateStaticInvitationCode = createAsyncThunk(
  'invitesTracker/updateStaticInvitationCode',
  async (payload, { rejectWithValue }) => {
    const { post } = useRequest();
    try {
      const response = await post(
        'Setting/UpdateStaticInvitationCode',
        payload
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data || 'Error updating invitation code'
      );
    }
  }
);

export const { setInvitesTrackerLoading, clearError } =
  invitesTrackerSlice.actions;

export const selectInvites = (state) => state.invitesTracker.invites;
export const selectUsers = (state) => state.invitesTracker.users;
export const selectTotalCount = (state) => state.invitesTracker.totalCount;
export const selectIsLoading = (state) => state.invitesTracker.isLoading;
export const selectError = (state) => state.invitesTracker.error;

export default invitesTrackerSlice.reducer;
