import { Button, Form, Input, Modal, notification, Select } from 'antd';
import React, { useState } from 'react';

import { useRequest } from '../../utils/hooks/requestHook';

const { Option } = Select;

const UpdateGroup = ({ open, onClose, id, name, description, privacy }) => {
  const { post } = useRequest();

  const [groupName, setGroupName] = useState(name);
  const [groupDescription, setGroupDescription] = useState(description);
  const [groupPrivacy, setGroupPrivacy] = useState(
    privacy === 'Public' ? 10 : 20
  );

  const handleUpdateGroup = async () => {
    try {
      const payload = {
        id,
        Name: groupName,
        Description: groupDescription,
        Privacy: groupPrivacy,
      };

      await post('/Group/UpdateGroup', payload);
      notification.success({
        message: 'Success',
        description: 'Group updated successfully.',
      });

      onClose();
    } catch (error) {
      console.error(
        'Error updating group:',
        error.response?.data || error.message
      );
      notification.error({
        message: 'Error',
        description: 'Failed to update group.',
      });
    }
  };

  return (
    <Modal title="Update Group" visible={open} onCancel={onClose} footer={null}>
      <Form layout="vertical">
        <Form.Item label="Group Name">
          <Input
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Privacy">
          <Select
            value={groupPrivacy}
            onChange={(value) => setGroupPrivacy(value)}
          >
            <Option value={10}>Public</Option>
            <Option value={20}>Private</Option>
          </Select>
        </Form.Item>
        <Button type="primary" onClick={handleUpdateGroup}>
          Update Group
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdateGroup;
